<template>
  <div class="page-contain">
    <breadcrumb :breadStaticData="breadStaticData" :linkData="linkData" :thirdStaticData="thirdStaticData"></breadcrumb>
    <div class="page-table">
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="plot(scope.row)">基地地块</el-button>
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
            <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
    </div>
    <base-edit ref="editForm" @getData="getData"></base-edit>
    <plot-list ref="plotForm" @getData="getData"></plot-list>
  </div>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import baseEdit from './baseEdit.vue'
  import plotList from './plotList.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  export default {
    name: 'baseList',
    components: {
      pagination,
      breadcrumb,
      plotList,
      baseEdit
    },
    props:{
      breadStaticData: {
        type: Array,
        default: () => ['主体信息', '基地信息']
      },
      pageName: {
        type: String,
        default: '基地信息'
      },
    },
    data() {
      let title = '基地地块/栏舍信息'
      return {
        allSelData: [],
        // breadStaticData:['主体信息', title],
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          bodyId: utils.getBodyId()
        },
        urlName: 'pssfindallbaseinfo',
        //基地名称、基地类型、基地面积（亩）、土壤、水源、产品、年产量、地理位置、地址、操作（修改、删除、查看）
        tableHead: [{
          prop: 'baseName',
          label: '基地名称'
        },{
          prop: 'baseTypeName',
          label: '基地类型',
          width: 150
        },{
          prop: 'baseScale',
          label: '基地面积（亩）',
          width: 120
        },{
          prop: 'soilType',
          label: '土壤'
        },{
          prop: 'waterType',
          label: '水源'
        },{
          prop: 'productName',
          label: '产品'
        },{
          prop: 'yearWeight',
          label: '年产量',
          width: 120
        },{
          prop: 'longitudeName',
          label: '地理位置',
          width: 160
        },{
          prop: 'baseAddress',
          label: '地址'
        }],
        loadtext: '加载中',
        // pageName: title,
        tableName: `${title}列表`,
        linkData:[],
        thirdStaticData:[],
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        }
      }
    },
    created() {
      this.initTable()
    },
    mounted(){
      this.getTopHeight()
    },
    methods: {
      async initTable(){
        this.getData()
      },
      plot(data){
        this.$refs.plotForm.initData(data.baseId || '')
      },
      async add(){
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add')
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.psfindbaseinfo({
          baseId: data.baseId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              url: result.url ? result.url.split(',') : [],
              // issueTime: result.issueTime ? utils.dateFormat(result.issueTime, 'yyyy-MM-dd') : '',
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.baseName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.pssdeletebaseinfo({
            baseId: data.baseId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                baseTypeName: utils.getConstToVal('baseType', item.baseType),
                yearWeight: (item.yearWeight || '') + (item.weightUnit || ''),
                longitudeName: `${item.longitude ?  '经度为：' + item.longitude : ''} ${item.latitude ?  '维度为：' + item.latitude : ''}`,
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>