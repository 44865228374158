<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="150px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="地块名称：" prop="plotName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.plotName }}</p>
                <el-input clearable v-else v-model="ruleForm.plotName" placeholder="请输入地块名称" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="种养产品：" prop="baseProductName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.baseProductName }}</p>
                <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.baseProductName" placeholder="请选择种养产品">
                  <el-button slot="append" @click="chooseType('product')" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.product && ruleForm.product.length">
            <el-col :span="24">
              <el-form-item label="" class="product-item">
                <div class="product-list" v-for="(item, index) in ruleForm.product" :key="index">
                  <p>{{ item.productName }}</p>
                  <i v-if="editType != 'look'" @click="delproduct(index)" class="el-icon-circle-close"></i>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.baseType != '05'">
            <el-col :span="24">
              <el-form-item label="占地面积：" prop="area">
                <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.area || '') + (ruleForm.areaUnit || '')}}</p>
                <el-input clearable v-else v-model="ruleForm.area" placeholder="请输入占地面积" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="ruleForm.areaUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.areaUnit"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="地块经度：" prop="longitude">
                <!-- <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.longitude }}</p> -->
                <el-input :disabled="true" class="my-input-btn my-input-btn2" v-model="ruleForm.longitude" :placeholder="editType == 'look'? '' : '请选择基地经度'">
                  <el-button slot="append" @click="chooseType('getamap')" type="primary">定位</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="地块纬度：" prop="latitude">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.latitude }}</p>
                <el-input :disabled="true" v-else v-model="ruleForm.latitude" placeholder="请选择地块纬度"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="地块平面图：" prop="url" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.url"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.url">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.url.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注：" prop="remark">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.remark }}</p>
                <el-input clearable v-else v-model="ruleForm.remark" placeholder="请输入备注" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pics ref="pics" dictCode="410002" :businessCode="ruleForm.plotId" :imgSize="0.5" propName="url" storeTableName="tableName" @getpics="getpics"></pics>
    <product-list ref="product" @getproductlist="getproductlist"></product-list>
    <getamap ref="getamap" @getamap="getamap"></getamap>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import productList from '@/views/common/productList.vue'
import pics from '@/views/common/pics.vue'
import getamap from '@/views/common/getamap2.vue'
let _t = null
export default {
  name: "plotEdit",
  components: {
    pics,
    productList,
    getamap
  },
  props:{
  },
  data() {
    return {   
      rules: {

        plotName: [{ required: true,  trigger: "change", message: '请输入地块名称'}],
        area: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入占地面积"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
        longitude: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
        latitude: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      initForm:{
        plotId: '',
        tableName: '',
        url: [],
        plotName: '',
        product: [],
        baseProductName: '',
        plotCode: '',
        area: '',
        areaUnit: '亩',
        latitude: '',
        longitude: '',
        remark: '',
        baseId: ''
      },
      selectObj: {
        areaUnit: CONSTPARAMS.areaUnit
      },
    };
  },
  created() {
	},
  mounted() {
    _t = this
  },
  methods: {
    getamap(data){
      this.ruleForm.longitude = data.long
      this.ruleForm.latitude = data.lat
    },
    getsupplylist(data){
      this.ruleForm.storeName = data.bodyName || ''
      this.ruleForm.storeId = data.supplierId || ''
    },
    chooseType(name){
      if(name == 'getamap'){
        if(this.editType == 'look' && !this.ruleForm.longitude && !this.ruleForm.latitude){
          this.message('无经纬度信息', "warning")
          return
        }
        this.$refs[name].initData(this.ruleForm.longitude, this.ruleForm.latitude, this.editType)
        return
      }
      this.$refs[name].initData()
    },
    getproductlist(cont){
      let id = cont.productId,
      name = `${cont.productName || ''}`,
      data = this.ruleForm.product || [],
      baseProductName = this.ruleForm.baseProductName,
      flag = true
      data.forEach(cur => {
        if(id == cur.productId) flag = false
      })
      if(!flag){
        this.message('已添加此条记录！', "warning")
        return
      }
      data.push({
        productId: id,
        productName: name
      })
      this.ruleForm.product = data || []
      this.ruleForm.baseProductName = baseProductName ? `${baseProductName},${name}` : `${name}`

    },
    delproduct(index){
      let data = this.ruleForm.product
      data.splice(index, 1)
      let productName = this.getRealLables(data)
      this.ruleForm.product = data || []
      this.ruleForm.baseProductName = productName || ''
    },
    getRealLables(data = []){
      let val = []
      if(data.length){
        data.forEach(cur => {
          val.push(cur.productName || '')
        })
      }
      return val.join(',')
    },
    addPics(){
      this.$refs.pics.initData(this.ruleForm.url)
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          ...transobj,
          plotId: id,
          product: []
        }
        this.addresses = []
        this.ruleForm = obj
      }else{
        this.ruleForm = { 
          ...ruleForm,
          baseProductName: this.getRealLables(ruleForm.product),
        }
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
      this.addresses = utils.getAddressByObj(this.ruleForm)
    },
    initLook(){
      this.initEdit()
    },
    async submitForm(flag = false) {
      let _this = this,
        editType = this.editType,
        formName = 'ruleForm',
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        },
        newId = flag ? await utils.getNewId() : ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'pssaveplot' : 'psupdateplot',
            target = {
            ..._this.ruleForm,
            url: _this.ruleForm.url?.join(',') || ''
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              if(flag){
                _this.ruleForm.batchId = newId
              }else{
                _this.closeModel()
                _this.$emit("getData", editType == 'add' ? true : false)
              }
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .product-item  ::v-deep .el-form-item__content{
    background-color: #fbfbfb;
    border: 1px solid #f3f3f3;
  }
  .product-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    padding: 0 10px;
  }
  .product-list i{
    cursor: pointer;
    font-size: 18px;
  }
</style>


