<template>
    <el-dialog
      class="my-dialog"
      :title="title"
      width="900px"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="closeHandle"
      append-to-body
      center
    >
    <el-row>
      <div class="amap-page-container">
        <el-amap 
          v-if="showInit"
          :vid="'amap-vue'"
          :zoom="zoom"
          :center="center"
          :events="events">
          <el-amap-marker 
            :position="marker.position"
            :events="marker.events"
            :visible="marker.visible"
            :draggable="marker.draggable">
          </el-amap-marker>
        </el-amap>
        <el-amap 
          v-else
          :vid="'amap-vue'"
          :zoom="zoom"
          :events="events">
          <el-amap-marker 
            :position="marker.position"
            :events="marker.events"
            :visible="marker.visible"
            :draggable="marker.draggable">
          </el-amap-marker>
        </el-amap>
      </div>
    </el-row>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" size="small" @click="submit">确定</el-button>
      <el-button @click="closeHandle" size="small">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { utils } from '@/utils/baseUtils'
  export default {
    name: "getamap2",
    data () {
      return {
        title: '选择经纬度',
        zoom: 14,
        center: [0, 0],
        marker: {
          position: [0, 0],
          visible: true,
          draggable: true
        },
        searchOption: {
          city: '全国',
          citylimit: true
        },
        addr: '',
        events: {
          click: (e) => {
            this.marker.position = [e.lnglat.lng, e.lnglat.lat]
            function regeocoder(lnglatXY, that) {
              AMap.plugin('AMap.Geocoder', function () {
                let geocoder = new AMap.Geocoder({
                  radius: 1000,
                  extensions: "all"
                });
                geocoder.getAddress(lnglatXY, function (status, result) {
                  if (status === 'complete' && result.info === 'OK') {
                    let address = result.regeocode.formattedAddress
                    console.log(address)
                    that.addr = address
                  }
                })
              })
            }
            regeocoder(this.marker.position, this)
          }
        },
        dialogVisible: false,
        showInit: false,
        editType: ''
      }
    },
    methods: {
      initData (lng, lat, editType) {
        this.editType = editType
        if (lng && lat) {
          this.center = [lng, lat]
          this.marker.position = [lng, lat]
          this.showInit = true
        }else{
          this.showInit = false
        }
        this.dialogVisible = true
      },
      
      submit () {
        let long = this.marker.position[0],
          lat = this.marker.position[1]

        if(long && lat){
          this.$emit('getamap', {
            long: utils.getFixedNumber(long),
            lat: utils.getFixedNumber(lat),
            address: this.addr
          })
          this.dialogVisible = false
        }else{
          this.message('请选择', "warning")
        }
      },
      closeHandle () {
        this.dialogVisible = false
      },
      onSearchResult (pois) {
        let latSum = 0
        let lngSum = 0
        pois.forEach(poi => {
          lngSum += poi.lng
          latSum += poi.lat
        })
        let center = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length
        }
        this.center = [center.lng, center.lat]
      },
    }
  }
</script>
<style scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
  .amap-page-container {
    margin-top: 0px;
    width: 100%;
    height: 580px;
  }
  .search-box {
    margin-top: 0px;
    position: relative;
    top: 65px;
    left: 20px;
  }
</style>