<template>
  <el-dialog
    class="my-dialog"
    :title="pageName"
    width="1100px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    center
  >
    <div>
      <div class="title-tab">
        <div class="title-tab-btns">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
        </div>
      </div>
      <el-table 
        ref="table" 
        class="my-table"
        size="small" 
        :data="listData" 
        highlight-current-row 
        border
        v-loading="loading"  
        :element-loading-text="loadtext" 
        :height="isDialog ? 'calc(100vh - 400px)' : tableHeight"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <el-table-column align="center" type="index" width="70" label="序号">
          <template slot-scope="scope">
            <span>{{
              pageparm
                ? (pageparm.page - 1) * pageparm.limit +
                  scope.$index +
                  1
                : scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          v-for="(headData, index) in tableHead"
          :key="index"
          :prop="headData.prop"
          :label="headData.label"
          :width="headData.width"
          :align="headData.align || 'center'"
          :header-align="headData.headerAlign || 'center'"
          :min-width="headData.minWidth"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            {{ scope.row[headData.prop] || '' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="180">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="view(scope.row, scope.$index)">查看</el-button>
            <el-button size="small" type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
            <el-button size="small" type="text" @click="del(scope.row, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-bind:child-msg="pageparm" @refreshTable="refreshTable"></pagination>
      <plot-edit ref="editForm"  @getData="getData"></plot-edit>
    </div>
  </el-dialog>
</template>
<script>
  import pagination from '@/components/pagination.vue'
  import breadcrumb from '@/components/breadcrumb.vue'
  import plotEdit from './plotEdit.vue'
  import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
  let title = '地块信息'
  export default {
    name: 'plot',
    components: {
      pagination,
      breadcrumb,
      plotEdit
    },
    props:{
      isDialog: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        timeRange: '',
        searchForm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          baseId: '',
        },
        urlName: 'pssfindallplot',
        //地块名称、品种、占地面积（亩）、地理位置、操作（修改、删除、查看）
        tableHead: [{
          prop: 'plotName',
          label: '地块名称'
        },{
          prop: 'productName',
          label: '品种'
        },{
          prop: 'area',
          label: '占地面积'
        },{
          prop: 'longitudeName',
          label: '地理位置',
          width: 200
        }],
        loadtext: '加载中',
        pageName: title,
        loading: false,
        tableHeight: null,
        listData: [],
        pageparm: {
          page: CONSTPARAMS.page,
          limit: CONSTPARAMS.limit,
          total: 0
        },
        closeFlag: false
      }
    },
    created() {
    },
    mounted(){
    },
    methods: {
      initData(baseId){
        this.closeFlag = true
        this.searchForm.baseId = baseId
        this.getData()
      },
      closeModel() {
        this.closeFlag = false
      },
      async add(){
        this.$refs.editForm.initData(`新增${this.pageName}`, 'add', {}, {
          baseId: this.searchForm.baseId
        })
      },
      view(data, index){
        this.getInfo('look', data, index)
      },
      edit(data, index){
        this.getInfo('edit', data, index)
      },
      getInfo(type, data, index){
        let _this = this
        request.psfindplotdetailed({
          plotId: data.plotId
        }).then(res => {
          if(res.code == 200){
            let result = res.data
            if(!result){
              _this.message('此条数据信息获取为空！', "error")
              return
            }
            result = {
              ...result,
              url: result.url ? result.url?.split(',') : []
            }
            delete result.createTime
            this.$refs.editForm.initData(`${type == 'edit' ? '修改' : '查看'}${this.pageName}`, type, result)
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      },
      del(data, index){
        let _this = this
        _this.$confirm(`确认要删除${data.plotName || ''}这条记录吗？`, "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
        .then(() => {
          request.psdeleteplot({
            plotId: data.plotId
          }).then(res => {
            if(res.code == 200){
              _this.getData()
              _this.message('删除成功！', "success");
            }else{
              _this.message(res.msg || '', "error");
            }
          }).catch(e => {
            console.log(e)
          })
        }).catch(() => {})
      },
      getTopHeight(){
        let getTopHeight = utils.getTopHeight()
        this.tableHeight = `calc(100% - ${getTopHeight}px)`
      },
      refreshTable(parm) {
        this.searchForm.page = parm.page
        this.searchForm.limit = parm.limit
        this.getData()
      },
      getData(flag = false){
        let _this = this
        if(flag){
          this.searchForm.page = CONSTPARAMS.page
          this.searchForm.limit = CONSTPARAMS.limit
        }
        _this.loading = true
        request[_this.urlName](_this.searchForm).then(res => {
          _this.loading = false
          if(res.code == 200){
            let lists = res.data.list || []
            lists = lists.map(item => {
              return {
                ...item,
                area: (item.area || '') + (item.areaUnit || '') ,
                longitudeName: `${item.longitude ?  '经度为：' + item.longitude : ''} ${item.latitude ?  '维度为：' + item.latitude : ''}`,
              }
            })
            _this.listData = lists
            _this.pageparm.page = _this.searchForm.page
            _this.pageparm.total = res.data.total || 0
            _this.$nextTick(() => {
              _this.$refs.table && _this.$refs.table.doLayout()
            })
          }else{
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
          _this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>
</style>